import { WithRequired } from "@properate/common";
import { DoubleDatapoint } from "@cognite/sdk";
import { Alert } from "antd";
import { useTranslations } from "@properate/translations";
import { BaseMeter } from "@/components/MeterSelectionModal/types";
import { MaximizableModal } from "@/components/MaximizableModal/MaximizableModal";
import { MeterDataProvider, useMeterData } from "./MeterDataContext";
import { ModalDataPointsDataTable } from "./ModalDataPointsDataTable";
import { ModalDataPointsUnitDescription } from "./ModalDataPointsUnitDescription";
import { AddModalRowButton } from "./AddModalRowButton";

type MeterWithDescription = WithRequired<BaseMeter, "description">;

interface ContextAwareModalProps {
  open: boolean;
  onOk: (value: {
    meter: MeterWithDescription;
    datapoints: DoubleDatapoint[];
  }) => unknown;
  onCancel: () => unknown;
  isLoading: boolean;
}

interface Props extends ContextAwareModalProps {
  meter?: MeterWithDescription;
  datapoints?: DoubleDatapoint[];
}

export function ModalDataPoints({ meter, datapoints, ...modalProps }: Props) {
  if (meter === undefined || datapoints === undefined) {
    return null;
  }

  return (
    <MeterDataProvider meter={meter} dataPoints={datapoints}>
      <ContextAwareModal {...modalProps} />
    </MeterDataProvider>
  );
}

function ContextAwareModal({
  open,
  onOk,
  onCancel,
  isLoading,
}: ContextAwareModalProps) {
  const { meter, dataPoints } = useMeterData();
  const t = useTranslations();

  function getCleanDataPoints() {
    return dataPoints.filter(({ value, timestamp }) => {
      return (
        value !== null &&
        value !== undefined &&
        timestamp !== null &&
        timestamp !== undefined
      );
    }) as DoubleDatapoint[];
  }

  function handleOk() {
    onOk({
      meter: meter as MeterWithDescription,
      datapoints: getCleanDataPoints(),
    });
  }
  const meterDescription = meter?.description;
  return (
    <MaximizableModal
      title={
        <h2 style={{ marginBottom: "0px" }}>
          {meterDescription
            ? t("timeseries.data-points-input.timeseries-meter-description", {
                meterDescription,
              })
            : null}
        </h2>
      }
      open={open}
      onOk={handleOk}
      onCancel={onCancel}
      destroyOnClose
      confirmLoading={isLoading}
      maskClosable={false}
      cancelButtonProps={{
        disabled: isLoading,
      }}
    >
      <Alert
        description={t(
          "timeseries.data-points-input.alert-register-data-points-description",
        )}
        type="info"
        showIcon
        closable
        style={{ marginBottom: "20px" }}
      />
      <ModalDataPointsUnitDescription />
      <ModalDataPointsDataTable />
      <AddModalRowButton />
    </MaximizableModal>
  );
}
