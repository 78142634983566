import { SortableElement } from "react-sortable-hoc";
import { Col } from "antd";
import { BuildingSpec, WithSnapshotId } from "@properate/common";
import { ParentSize } from "@visx/responsive";
import { useTranslations } from "@properate/translations";
import { DashboardStandardWidget, DashboardWidget } from "@/utils/types";
import { BuildingCertificationsWidget } from "@/components/building-certifications";
import { BuildingContactsWidget } from "@/components/building-contacts";
import { BuildingTenantsWidget } from "@/components/building-tenants";
import { SpotPriceWidget } from "@/components/SpotPrice";
import { useUserSettings } from "@/services/userSettings";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { Gauge } from "@/features/gauges";
import { Analysis } from "@/features/analysis";
import { HeatMap } from "@/features/heatmap";
import { SolarCellProductionWidget } from "@/components/SolarCellProduction";
import { IncidentsWidget } from "../widgets/IncidentsWidget";
import { NotesWidget } from "../widgets/NotesWidget";
import { EnergyUseThisWeekWidget } from "../widgets/EnergyUseThisWeekWidget";
import { EnergyUsedThisWeekEpredWidget } from "../widgets/EnergyUsedThisWeekEPredWidget/EnergyUsedThisWeekEpredWidget";
import { EnergyFlowWidget } from "../widgets/EnergyFlowWidget";
import { AccumulatedEnergyWidget } from "../widgets/AccumulatedEnergyWidget";
import { RollingAverageWidget } from "../widgets/RollingAverageWidget";
import { WeatherWidget } from "../widgets/WeatherWidget/WeatherWidget";
import { AlarmsWidget } from "../widgets/AlarmsWidget";
import { IndoorClimateWidget } from "../widgets/IndoorClimateWidget";
import { GaugeWidget } from "../widgets/GaugeWidget";
import { AnalysisWidget } from "../widgets/AnalysisWidget/AnalysisWidget";
import { HeatMapWidget } from "../widgets/HeatMapWidget";

interface Props {
  selectedGauges: WithSnapshotId<Gauge>[];
  selectedAnalyses: WithSnapshotId<Analysis>[];
  selectedHeatMaps: WithSnapshotId<HeatMap>[];
  widget: DashboardWidget;
  buildingSpec: BuildingSpec;
  height: number;
  organizationsToTotalEnergyTimeseriesIds: Record<string, number>;
  organizationsToTotalEnergyTempCorrectedETModTimeseriesIds: Record<
    string,
    number
  >;
  organizationsToTotalEnergyTempCorrectedGraddagTimeseriesIds: Record<
    string,
    number
  >;
  organizations: string[];
  onRemoveWidget: () => unknown;
}

export const SortableWidget = SortableElement<Props>(
  ({
    selectedGauges,
    selectedAnalyses,
    selectedHeatMaps,
    height,
    organizationsToTotalEnergyTimeseriesIds,
    organizationsToTotalEnergyTempCorrectedETModTimeseriesIds,
    organizationsToTotalEnergyTempCorrectedGraddagTimeseriesIds,
    buildingSpec,
    widget,
    organizations,
    onRemoveWidget,
  }: Props) => {
    const t = useTranslations();

    const { data: preferences } = useUserSettings();
    const currentBuilding = useCurrentBuilding();
    if (
      widget.id === "energyUseThisWeek" ||
      widget.id === "energyUseThisWeekTempCorrectedGraddag" ||
      widget.id === "energyUseThisWeekTempCorrectedEtmod"
    ) {
      const organizationsToTotalEnergyTimeseriesIdsFinal =
        widget.id === "energyUseThisWeek"
          ? organizationsToTotalEnergyTimeseriesIds
          : widget.id === "energyUseThisWeekTempCorrectedGraddag"
          ? organizationsToTotalEnergyTempCorrectedGraddagTimeseriesIds
          : organizationsToTotalEnergyTempCorrectedETModTimeseriesIds;
      return (
        <Col lg={12} xs={24} key={widget.id} style={{ minHeight: height }}>
          <ParentSize>
            {({ width }) => (
              <EnergyUseThisWeekWidget
                buildingId={currentBuilding.id}
                width={width}
                height={height}
                organizationsToTotalEnergyTimeseriesIds={
                  organizationsToTotalEnergyTimeseriesIdsFinal
                }
                closedPublicHolidays={buildingSpec.closedPublicHolidays}
                operationalHours={buildingSpec.operationalHours}
                organizations={organizations}
                onClickRemoveButton={onRemoveWidget}
                type={
                  widget.id as Extract<
                    DashboardStandardWidget,
                    | "energyUseThisWeek"
                    | "energyUseThisWeekTempCorrectedEtmod"
                    | "energyUseThisWeekTempCorrectedGraddag"
                  >
                }
              />
            )}
          </ParentSize>
        </Col>
      );
    }
    if (widget.id === "energyUseThisWeekEpred") {
      return (
        <Col xl={12} xs={24} key={widget.id} style={{ minHeight: height }}>
          <ParentSize>
            {({ width }) => (
              <EnergyUsedThisWeekEpredWidget
                width={width}
                height={height}
                closedPublicHolidays={buildingSpec.closedPublicHolidays}
                operationalHours={buildingSpec.operationalHours}
                onClickRemoveButton={onRemoveWidget}
              />
            )}
          </ParentSize>
        </Col>
      );
    }
    if (widget.id === "sankeyDiagram") {
      return (
        <Col xl={12} xs={24} key={widget.id} style={{ minHeight: height }}>
          <EnergyFlowWidget
            height={height}
            id={currentBuilding.id}
            preferences={preferences!}
            onClickRemoveButton={onRemoveWidget}
          />
        </Col>
      );
    }
    if (widget.id === "consumptionLast7Days") {
      return (
        <Col lg={12} xs={24} key={widget.id} style={{ minHeight: height }}>
          <ParentSize>
            {({ width }) => (
              <RollingAverageWidget
                id={currentBuilding.id}
                width={width}
                height={height}
                energy={organizationsToTotalEnergyTimeseriesIds}
                operationalHours={buildingSpec.operationalHours}
                closedPublicHolidays={buildingSpec.closedPublicHolidays}
                buildingId={currentBuilding.id}
                organizations={organizations}
                onClickRemoveButton={onRemoveWidget}
              />
            )}
          </ParentSize>
        </Col>
      );
    }
    if (widget.id === "weather") {
      return (
        <Col lg={12} xs={24} key={widget.id}>
          <ParentSize>
            {({ width }) => (
              <WeatherWidget
                lat={Number(currentBuilding.metadata!.Lat)}
                long={Number(currentBuilding.metadata!.Lon)}
                width={width}
                height={height}
                onClickRemoveButton={onRemoveWidget}
              />
            )}
          </ParentSize>
        </Col>
      );
    }
    if (
      widget.id === "accumulatedEnergyUse" ||
      widget.id === "accumulatedEnergyUseTempCorrectedGraddag" ||
      widget.id === "accumulatedEnergyUseTempCorrectedEtmod"
    ) {
      const organizationsToTotalEnergyTimeseriesIdsFinal =
        widget.id === "accumulatedEnergyUse"
          ? organizationsToTotalEnergyTimeseriesIds
          : widget.id === "accumulatedEnergyUseTempCorrectedGraddag"
          ? organizationsToTotalEnergyTempCorrectedGraddagTimeseriesIds
          : organizationsToTotalEnergyTempCorrectedETModTimeseriesIds;
      return (
        <Col xl={12} xs={24} key={widget.id}>
          <ParentSize>
            {({ width }) => (
              <AccumulatedEnergyWidget
                buildingId={currentBuilding.id.toString()}
                energy={organizationsToTotalEnergyTimeseriesIdsFinal}
                width={width}
                height={height}
                organizations={organizations}
                onClickRemoveButton={onRemoveWidget}
                type={
                  widget.id as Extract<
                    DashboardStandardWidget,
                    | "accumulatedEnergyUse"
                    | "accumulatedEnergyUseTempCorrectedGraddag"
                    | "accumulatedEnergyUseTempCorrectedEtmod"
                  >
                }
              />
            )}
          </ParentSize>
        </Col>
      );
    }
    if (widget.id === "alarms") {
      return (
        <Col xl={12} xs={24} key={widget.id}>
          <ParentSize>
            {({ width }) => (
              <AlarmsWidget
                id={currentBuilding.id}
                width={width}
                height={height}
                onClickRemoveButton={onRemoveWidget}
              />
            )}
          </ParentSize>
        </Col>
      );
    }
    if (widget.id === "incidents") {
      return (
        <Col xl={12} xs={24} key={widget.id}>
          <ParentSize>
            {({ width }) => (
              <IncidentsWidget
                id={currentBuilding.id}
                width={width}
                height={height}
                onClickRemoveButton={onRemoveWidget}
              />
            )}
          </ParentSize>
        </Col>
      );
    }
    if (widget.id === "indoorClimate") {
      return (
        <Col lg={12} xs={24} key={widget.id}>
          <ParentSize>
            {({ width }) => (
              <IndoorClimateWidget
                id={currentBuilding.id}
                width={width}
                height={height}
                spec={buildingSpec}
                onClickRemoveButton={onRemoveWidget}
              />
            )}
          </ParentSize>
        </Col>
      );
    }
    if (widget.id === "contacts") {
      return (
        <Col xl={12} xs={24} key={widget.id}>
          <ParentSize>
            {({ width }) => (
              <BuildingContactsWidget
                width={width}
                height={height}
                onClickRemoveButton={onRemoveWidget}
              />
            )}
          </ParentSize>
        </Col>
      );
    }
    if (widget.id === "certifications") {
      return (
        <Col xl={12} xs={24} key={widget.id}>
          <ParentSize>
            {({ width }) => (
              <BuildingCertificationsWidget
                width={width}
                height={height}
                onClickRemoveButton={onRemoveWidget}
              />
            )}
          </ParentSize>
        </Col>
      );
    }
    if (widget.id === "tenants") {
      return (
        <Col xl={12} xs={24} key={widget.id}>
          <ParentSize>
            {({ width }) => (
              <BuildingTenantsWidget
                width={width}
                height={height}
                onClickRemoveButton={onRemoveWidget}
              />
            )}
          </ParentSize>
        </Col>
      );
    }
    if (widget.id === "solarCellProduction") {
      return (
        <Col xxl={12} xs={24} key={widget.id}>
          <ParentSize>
            {({ width }) => (
              <SolarCellProductionWidget
                width={width}
                height={height}
                onClickRemoveButton={onRemoveWidget}
              />
            )}
          </ParentSize>
        </Col>
      );
    }
    if (widget.id === "spotPrice") {
      return (
        <Col lg={12} xs={24} key={widget.id}>
          <ParentSize>
            {({ width }) => (
              <SpotPriceWidget
                width={width}
                height={height}
                onClickRemoveButton={onRemoveWidget}
              />
            )}
          </ParentSize>
        </Col>
      );
    }
    if (widget.type === "gauge") {
      const gauge = selectedGauges.find(
        (gauge) => gauge.snapshotId === widget.id,
      );
      return (
        gauge && (
          <Col xxl={6} lg={12} xs={24}>
            <ParentSize>
              {({ width }) => (
                <GaugeWidget
                  gauge={gauge}
                  height={height}
                  width={width}
                  onClickRemoveButton={onRemoveWidget}
                />
              )}
            </ParentSize>
          </Col>
        )
      );
    }
    if (widget.type === "analysis") {
      const analysis = selectedAnalyses.find(
        (analysis) => analysis.snapshotId === widget.id,
      );
      return (
        analysis && (
          <Col lg={12} xs={24}>
            <ParentSize>
              {({ width }) => (
                <AnalysisWidget
                  analysis={analysis}
                  height={height}
                  width={width}
                  onClickRemoveButton={onRemoveWidget}
                />
              )}
            </ParentSize>
          </Col>
        )
      );
    }

    if (widget.type === "heatMap") {
      const heatMap = selectedHeatMaps.find(
        (heatMap) => heatMap.snapshotId === widget.id,
      );
      return (
        heatMap && (
          <Col lg={12} xs={24}>
            <ParentSize>
              {({ width }) => (
                <HeatMapWidget
                  heatMap={heatMap}
                  width={width}
                  height={height}
                  onClickRemoveButton={onRemoveWidget}
                />
              )}
            </ParentSize>
          </Col>
        )
      );
    }
    if (
      widget.id === "notes" &&
      typeof currentBuilding?.dataSetId === "number"
    ) {
      return (
        <Col xl={12} xs={24} key={widget.id}>
          <ParentSize>
            {({ width }) => (
              <NotesWidget
                width={width}
                height={height}
                onClickRemoveButton={onRemoveWidget}
                showLevel={
                  preferences?.buildings?.[currentBuilding.id]?.notes?.showLevel
                }
              />
            )}
          </ParentSize>
        </Col>
      );
    }
    console.error("Ukjent widget type", widget.type, "(id -", widget.id, ")");
    return (
      <div>
        {t("dashboard.widgets.unknown-type", {
          type: widget.type,
          id: widget.id,
        })}
      </div>
    );
  },
);
