import { Col, Form, Row, Tag } from "antd";
import { useTranslations, MessageKey } from "@properate/translations";
import { isValid } from "@/components/MeterSelectionModal/utils";
import { BaseMeter } from "@/components/MeterSelectionModal/types";
import { bbByMeterType } from "./MeterSelectionNodeContent";

const FormItem = Form.Item;

interface Props {
  meter: BaseMeter;
  name: string | null;
  labels: string[];
}

function MeterDetails({ meter, name, labels }: Props) {
  const t = useTranslations();

  if (!isValid(meter)) return <></>;

  const bb = bbByMeterType[meter.meterType];

  return (
    <>
      <Row gutter={24}>
        <Col span={24}>
          <FormItem label={t("calculation-flow.meter-details.name")}>
            {name}
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem label={t("calculation-flow.meter-details.sub-building")}>
            {meter.subBuilding}
          </FormItem>
        </Col>
        {meter.system !== null && (
          <Col span={8}>
            <FormItem label={t("calculation-flow.meter-details.system")}>
              {meter.system}
              <br />(
              {t(
                `tfm.system.buildingPartNumber.${Number(
                  meter.system.substring(0, 3),
                )}` as MessageKey,
              )}
              )
            </FormItem>
          </Col>
        )}
        <Col span={8}>
          <FormItem label={t("calculation-flow.meter-details.component")}>
            {bb && `${bb} (${t(("tfm.bb-descriptions." + bb) as MessageKey)})`}
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem label={t("calculation-flow.meter-details.unit")}>
            {meter.unit}
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem label={t("calculation-flow.meter-details.labels")}>
            {labels.map((label) => (
              <Tag key={label}>{label}</Tag>
            ))}
          </FormItem>
        </Col>
      </Row>
    </>
  );
}

export default MeterDetails;
