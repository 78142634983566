import { ThirdPartyAlarmVisualTimeseries } from "@properate/common";
import useSWR from "swr";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { CogniteInternalId, Timeseries } from "@cognite/sdk";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useFormValue } from "../../FormContext/hooks";
import { getColor } from "../../components/AlarmTimeseriesGraph/utils";
import { ThirdPartyAlarmFields } from "./types";

export function useThirdPartyAlarmTimeseriesListWithColors() {
  const [timeseriesId] = useFormValue<number | undefined>(
    ThirdPartyAlarmFields.BaseTimeseries,
  );
  const [visualTimeseries] = useFormValue<ThirdPartyAlarmVisualTimeseries[]>(
    ThirdPartyAlarmFields.VisualTimeseries,
  );
  const { client, isAuthenticated } = useCogniteClient();
  const themeContext = useContext(ThemeContext);

  const { data, isLoading, error } = useSWR(
    [timeseriesId, visualTimeseries, "thirdPartyAlarmTimeseriesWithColors"],
    async ([timeseriesId, visualTimeseries]) => {
      if (!isAuthenticated) {
        return [];
      }
      const baseTimeseriesId = timeseriesId ? [timeseriesId] : [];
      const visualTimeseriesIds = visualTimeseries
        .map((visualTS) => visualTS.value)
        .filter((id) => id !== undefined);

      const uniqueTimeseriesIds = [
        ...new Set([...baseTimeseriesId, ...visualTimeseriesIds]),
      ].map((id) => ({ id }));

      return await client.timeseries.retrieve(uniqueTimeseriesIds);
    },
  );

  let timeseriesList = undefined;

  if (data !== null && data !== undefined) {
    timeseriesList = data.map((ts, index) => ({
      timeseries: ts,
      color: getColor(index, themeContext),
    }));
  }

  return { timeseriesList, isLoading, error };
}

export function useThirdPartyAlarmTimeseriesWithColor(
  timeseriesId: CogniteInternalId | undefined,
): { timeseries?: Timeseries; color?: string } {
  const { timeseriesList } = useThirdPartyAlarmTimeseriesListWithColors();

  if (!timeseriesList) {
    return {};
  }

  const { timeseries, color } =
    (timeseriesList ?? []).find(
      ({ timeseries }) => timeseries?.id === timeseriesId,
    ) ?? {};

  if (!timeseries || !color) {
    return {};
  }

  return { timeseries, color };
}

export function useBaseIsAlsoVisual() {
  const [baseTimeseriesId] = useFormValue<number | undefined>(
    ThirdPartyAlarmFields.BaseTimeseries,
  );
  const [visualTimeseries] = useFormValue<
    ThirdPartyAlarmVisualTimeseries[] | undefined
  >(ThirdPartyAlarmFields.VisualTimeseries);

  if (!visualTimeseries) {
    return true;
  }

  return visualTimeseries.some(({ value }) => value === baseTimeseriesId);
}
