import { browserFirestore } from "@properate/firebase/src";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Icon from "@ant-design/icons";
import { Button, Col, Menu, Popover, Row } from "antd";
import { IoMapOutline } from "@react-icons/all-files/io5/IoMapOutline";
import { useState } from "react";
import { getAuth, signOut } from "firebase/auth";
import { getApp } from "firebase/app";
import { clearIndexedDbPersistence, terminate } from "firebase/firestore";
import { OperationalHoursType } from "@properate/common";
import { useUser } from "@properate/auth";
import {
  useTranslations,
  MessageKey,
  useLocale,
} from "@properate/translations";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import keycloak from "@/keycloak";
import { useAliasConfig } from "@/services/alias";
import { useBuildingSpec } from "@/services/buildingSpec";
import { useCurrentBuildingAreal } from "@/hooks/useCurrentBuildingAreal";
import {
  isMobile,
  isSidebarCollapsedResponsive,
} from "@/utils/responsiveDesign";
import {
  mutateUserSettings,
  useUserSettings,
} from "../../services/userSettings";
import { BuildingInfo } from "../BuildingInfo";
import { ReactComponent as BuildingSVG } from "../icons/home_work.svg";
import { BuildingSwitcher } from "../BuildingsSwitcher";
import { Help } from "../Help";
import { BuildingSideMenu } from "../BuildingSideMenu";
import { ReactComponent as AccountSVG } from "../bottomIcons/account_circle.svg";
import { ReactComponent as SettingsSVG } from "../bottomIcons/settings.svg";
import { ReactComponent as HelpSVG } from "../bottomIcons/help_outline.svg";
import { ReactComponent as TriggerSVG } from "../bottomIcons/trigger.svg";
import * as SC from "./elements";

type Props = {
  children: React.ReactNode;
};

export function BuildingLayout(props: Props) {
  const t = useTranslations();

  const navigate = useNavigate();
  const currentBuildingId = useCurrentBuildingId();
  const [showSelectBuilding, setShowSelectBuilding] = useState(false);
  const { data: preferences } = useUserSettings();
  const isSidebarCollapsed = isSidebarCollapsedResponsive(preferences);
  const location = useLocation();

  // Can't use overflow: hidden on these pages as position: sticky does not work on the page's header in that case
  // See "SchemaView" component & https://robertmarshall.dev/blog/solution-to-why-css-position-sticky-is-not-working/
  const isOnFloorPlanOrTechnicalSchemaDetailsRoute =
    /(technicalSchema|floorPlan)\/\w+\/(view|edit)/.test(location.pathname);

  return (
    <SC.Container>
      <SC.Sidebar data-collapsed={isSidebarCollapsed}>
        <Link to="/">
          <SC.Logo />
        </Link>
        <Popover
          overlayStyle={{ width: 360 }}
          content={<BuildingPopoverContent />}
          placement="rightBottom"
          trigger="click"
        >
          <div>
            <BuildingInfo />
          </div>
        </Popover>
        <SC.MapChangeBuildingButtons>
          <Menu
            mode="inline"
            inlineCollapsed={isSidebarCollapsed}
            selectable={false}
            items={[
              {
                key: "select-building",
                label: t("common.sidebar.change-building"),
                icon: <Icon component={BuildingSVG} />,
                onClick: () => setShowSelectBuilding(true),
              },
              {
                key: "map",
                label: t("common.sidebar.show-on-map"),
                icon: <Icon component={IoMapOutline} />,
                onClick: () => navigate(`/map/${currentBuildingId}`),
              },
            ]}
          />
        </SC.MapChangeBuildingButtons>
        <SC.MenuContainer>
          <BuildingSideMenu inlineCollapsed={isSidebarCollapsed} />
        </SC.MenuContainer>
        <SC.SidebarFooter>
          <FooterOptions />
        </SC.SidebarFooter>
      </SC.Sidebar>
      <SC.Content hideOverflow={!isOnFloorPlanOrTechnicalSchemaDetailsRoute}>
        {props.children}
      </SC.Content>
      <BuildingSwitcher
        visible={showSelectBuilding}
        onHide={() => setShowSelectBuilding(false)}
      />
    </SC.Container>
  );
}

function FooterOptions() {
  const locale = useLocale();
  const user = useUser();
  const navigate = useNavigate();
  const currentBuildingId = useCurrentBuildingId();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const { data: preferences } = useUserSettings();
  const isSidebarCollapsed = isSidebarCollapsedResponsive(preferences);
  const t = useTranslations();

  const logout = async () => {
    await signOut(getAuth(getApp()));
    localStorage.removeItem("user");
    keycloak.logout();
  };

  const refresh = async () => {
    await terminate(browserFirestore);
    await clearIndexedDbPersistence(browserFirestore);
    await fetch(document.location.href, {
      headers: {
        Pragma: "no-cache",
        Expires: "-1",
        "Cache-Control": "no-cache",
      },
    });
    window.location.href = document.location.href;
    // This is to ensure reload with url's having '#'
    window.location.reload();
  };

  const changeLanguage = () => {
    mutateUserSettings({ language: locale === "en" ? "no" : "en" });
  };

  return (
    <>
      <div>
        <Popover
          open={showUserMenu}
          onOpenChange={(e) => setShowUserMenu(e)}
          placement="topLeft"
          content={
            <>
              {user.email}
              <hr />
              {Boolean(user.isSuperAdmin) && (
                <p>
                  <Button
                    type="link"
                    onClick={() => {
                      navigate(
                        `/asset/${currentBuildingId}/organization-admin`,
                      );
                      setShowUserMenu(false);
                    }}
                  >
                    {t("layout.footer.organization-admin")}
                  </Button>
                </p>
              )}
              {Boolean(user.isAdmin) && (
                <p>
                  <Button
                    type="link"
                    onClick={() => {
                      navigate(`/asset/${currentBuildingId}/admin`);
                      setShowUserMenu(false);
                    }}
                  >
                    {t("layout.footer.user-admin")}
                  </Button>
                </p>
              )}
              <p>
                <Button type="link" onClick={() => logout()}>
                  {t("layout.footer.logout")}
                </Button>
              </p>
              <p>
                <Button type="link" onClick={refresh} danger>
                  {t("layout.footer.clear-cache")}
                </Button>
              </p>
              <p>
                <Button type="link" onClick={changeLanguage}>
                  {t("layout.footer.switch-to-language", {
                    language: locale === "en" ? "Norwegian" : "engelsk",
                  })}
                </Button>
              </p>
            </>
          }
          trigger="click"
        >
          <Button
            type="text"
            size="large"
            icon={<Icon component={AccountSVG} />}
          />
        </Popover>
        <Button
          type="text"
          size="large"
          disabled={Boolean(user.isAdmin) === false}
          aria-label="Innstillinger"
          icon={
            <Icon
              component={SettingsSVG}
              aria-hidden
              onClick={() => {
                navigate(`/asset/${currentBuildingId}/settings`);
              }}
            />
          }
        />
        <Button
          type="text"
          size="large"
          icon={<Icon component={HelpSVG} />}
          onClick={() => setShowHelp(!showHelp)}
        />
      </div>
      <Button
        type="text"
        size="large"
        style={{ transform: isSidebarCollapsed ? "rotate(180deg)" : "" }}
        onClick={async () => {
          await mutateUserSettings(
            isMobile()
              ? {
                  isMobileSidebarCollapsed: !isSidebarCollapsed,
                }
              : {
                  isSidebarCollapsed: !isSidebarCollapsed,
                },
          );
        }}
        icon={<Icon component={TriggerSVG} />}
      />
      <Help setShowHelp={setShowHelp} showHelp={showHelp} />
    </>
  );
}

function BuildingPopoverContent() {
  const t = useTranslations();

  const user = useUser();
  const aliasConfig = useAliasConfig(user.ownerAlias);
  const currentBuildingId = useCurrentBuildingId();
  const navigate = useNavigate();
  const spec = useBuildingSpec(currentBuildingId);
  const usableFloorArea = useCurrentBuildingAreal(currentBuildingId);

  return (
    <Row gutter={[16, 8]}>
      <Col span={22}>
        {(aliasConfig?.buildings && aliasConfig.buildings[currentBuildingId]) ||
          ""}
      </Col>
      <Col span={2}>
        <Button
          type="link"
          disabled={Boolean(user.isAdmin)}
          style={{ float: "right" }}
          icon={
            <Icon
              component={SettingsSVG}
              onClick={() => {
                navigate(`/asset/${currentBuildingId}/settings`);
              }}
            />
          }
        />
      </Col>
      <Col span={24}>
        <img
          alt={spec?.name}
          src={spec?.imageUrl}
          style={{ width: "100%", height: "auto" }}
        />
      </Col>
      <Col span={24}>
        <Row gutter={[8, 8]}>
          <Col span={12}>{t("common.sidebar.year-of-construction")}</Col>
          <Col span={12}>{spec?.yearBuilt}</Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>{t("common.sidebar.use-area")}</Col>
          <Col span={12}>
            {usableFloorArea?.area !== undefined
              ? usableFloorArea.area + " m²"
              : ""}
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>{t("common.sidebar.building-category")}</Col>
          <Col span={12}>
            {spec?.buildingCategory !== undefined &&
              t(
                `common.building-category.${spec.buildingCategory.toLowerCase()}` as MessageKey,
              )}
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>{t("common.sidebar.building-type")}</Col>
          <Col span={12}>
            {spec?.buildingType !== undefined &&
              t(
                `common.building-type.${spec.buildingType.toLowerCase()}` as MessageKey,
              )}
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>{t("common.sidebar.open-hours")}</Col>
          <Col span={12}>
            {spec?.operationalHours &&
              spec.operationalHours.map(
                (op: OperationalHoursType, index: number) => (
                  <p key={index}>
                    {t(
                      `common.day-periods.${op.period.toLowerCase()}` as MessageKey,
                    )}
                    : {op.start} - {op.end}
                  </p>
                ),
              )}
            <p>
              {spec?.closedPublicHolidays === true &&
                t("common.sidebar.closed-on-holidays")}
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
