import { createContext, useContext } from "react";
import type { Pin } from "./types";

const FloorPlanPinContext = createContext<Pin | null>(null);

type Props = {
  pin: Pin;
  children: React.ReactNode;
};

export function FloorPlanPinProvider(props: Props) {
  return (
    <FloorPlanPinContext.Provider value={props.pin}>
      {props.children}
    </FloorPlanPinContext.Provider>
  );
}

export function useFloorPlanPin() {
  const pin = useContext(FloorPlanPinContext);

  if (pin === null) {
    throw new Error(
      "useFloorPlanPin must be used within a FloorPlanPinProvider",
    );
  }

  return pin;
}

export function useFloorPlanPinEnsuringTimeseriesId() {
  const pin = useFloorPlanPin();

  if (pin.type === "room" || pin.type === "label") {
    throw new Error(
      "Expected pin to have a timeseries id, but got a room or label pin instead.",
    );
  }

  return pin;
}
