import { createContext, useContext, useEffect, useReducer } from "react";
import { uniqBy } from "lodash";
import { App } from "antd";
import { useTranslations } from "@properate/translations";
import { BaseMeter } from "@/components/MeterSelectionModal/types";
import { parseDatapointsFromPaste } from "@/features/timeseries";
import type { Dispatch, PropsWithChildren } from "react";

export enum DatapointReducerActionType {
  addRow,
  addRowAtIndex,
  removeRow,
  editDataPoint,
  editMeter,
  pasteDataPoints,
  clearFocus,
}

export interface DataPoint {
  value: number | null | undefined;
  timestamp: Date | null | undefined;
  focus?: boolean;
}

interface MeterData {
  meter: BaseMeter;
  dataPoints: DataPoint[];
}

export type MeterDataReducerAction =
  | {
      type:
        | DatapointReducerActionType.addRow
        | DatapointReducerActionType.clearFocus;
    }
  | {
      type:
        | DatapointReducerActionType.removeRow
        | DatapointReducerActionType.addRowAtIndex;
      rowIndex: number;
    }
  | {
      type: DatapointReducerActionType.editMeter;
      fieldName: keyof BaseMeter;
      fieldValue: string;
    }
  | {
      type: DatapointReducerActionType.editDataPoint;
      rowIndex: number;
      fieldName: keyof DataPoint;
      fieldValue: Date | number | null;
    }
  | {
      type: DatapointReducerActionType.pasteDataPoints;
      datapointsFromPaste: DataPoint[];
    };

function makeEmptyDataPoint(focus?: boolean): DataPoint {
  return { value: 0, timestamp: null, focus };
}

function meterDataReducer(
  meterData: MeterData,
  action: MeterDataReducerAction,
) {
  function validateEditAction() {
    if (
      !("fieldName" in action) ||
      !("fieldValue" in action) ||
      !action.fieldName
    ) {
      throw Error(
        `Invalid action: attempting "${action.type}" without valid fieldName or fieldValue`,
      );
    }
  }

  switch (action.type) {
    case DatapointReducerActionType.addRow: {
      return {
        ...meterData,
        dataPoints: [...meterData.dataPoints, makeEmptyDataPoint(true)],
      };
    }
    case DatapointReducerActionType.addRowAtIndex: {
      return {
        ...meterData,
        dataPoints: meterData.dataPoints.toSpliced(
          action.rowIndex,
          0,
          makeEmptyDataPoint(true),
        ),
      };
    }
    case DatapointReducerActionType.removeRow: {
      return {
        ...meterData,
        dataPoints: meterData.dataPoints.toSpliced(action.rowIndex, 1),
      };
    }
    case DatapointReducerActionType.editDataPoint: {
      validateEditAction();
      const row = {
        ...meterData.dataPoints[action.rowIndex],
        [action.fieldName as keyof DataPoint]: action.fieldValue,
      };
      return {
        ...meterData,
        dataPoints: meterData.dataPoints.toSpliced(action.rowIndex, 1, row),
      };
    }
    case DatapointReducerActionType.editMeter: {
      validateEditAction();
      return {
        ...meterData,
        meter: {
          ...meterData.meter,
          [action.fieldName as keyof BaseMeter]: action.fieldValue,
        },
      };
    }
    case DatapointReducerActionType.pasteDataPoints: {
      return {
        ...meterData,
        dataPoints: uniqBy(
          meterData.dataPoints
            .filter(
              (dataPoint) =>
                dataPoint.timestamp !== null &&
                dataPoint.timestamp !== undefined,
            )
            .concat(action.datapointsFromPaste),
          (dataPoint) => dataPoint.timestamp?.valueOf(),
        ),
      };
    }
    case DatapointReducerActionType.clearFocus: {
      return {
        ...meterData,
        dataPoints: meterData.dataPoints.map((dataPoint) => ({
          ...dataPoint,
          focus: undefined,
        })),
      };
    }
  }
}

const MeterDataContext = createContext<MeterData | null>(null);
const MeterDataDispatchContext =
  createContext<Dispatch<MeterDataReducerAction> | null>(null);

export interface MeterDataProviderProps extends PropsWithChildren {
  meter: BaseMeter;
  dataPoints?: DataPoint[];
}

export function MeterDataProvider({
  children,
  meter,
  dataPoints,
}: MeterDataProviderProps) {
  const t = useTranslations();
  const [meterData, dispatch] = useReducer(meterDataReducer, {
    meter: meter,
    dataPoints: [...(dataPoints || []), makeEmptyDataPoint()],
  });

  const { message } = App.useApp();

  useEffect(() => {
    function handlePasteEvent(event: ClipboardEvent) {
      if (event.target && event.target instanceof HTMLInputElement) {
        return;
      }
      const data = event.clipboardData?.getData("text");
      if (typeof data !== "string") {
        console.error("Unable to parse paste event: not string data");
        message.warning({
          content: t(
            "timeseries.meter-selection-modal.unable-to-interpret-data",
          ),
        });
        return;
      }
      const datapointsFromPaste = parseDatapointsFromPaste(data as string);
      if (datapointsFromPaste.length <= 0) {
        console.error("Unable to parse paste event: no datapoints found");
        message.warning({
          content: t(
            "timeseries.meter-selection-modal.unable-to-interpret-data",
          ),
        });
        return;
      }
      dispatch({
        type: DatapointReducerActionType.pasteDataPoints,
        datapointsFromPaste,
      });
    }

    window.addEventListener("paste", handlePasteEvent);
    return () => window.removeEventListener("paste", handlePasteEvent);
  }, [message, t]);

  return (
    <MeterDataContext.Provider value={meterData}>
      <MeterDataDispatchContext.Provider value={dispatch}>
        {children}
      </MeterDataDispatchContext.Provider>
    </MeterDataContext.Provider>
  );
}

export function useMeterData() {
  return useContext(MeterDataContext) as MeterData;
}

export function useMeterDataDispatch() {
  return useContext(
    MeterDataDispatchContext,
  ) as Dispatch<MeterDataReducerAction>;
}
