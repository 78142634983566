import { PropsWithChildren } from "react";
import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { MessageKey, useTranslations } from "@properate/translations";
import { cn } from "@properate/ui";

export enum BoxVariant {
  Info,
  Warning,
  Error,
}

export function Box({
  children,
  variant,
}: Readonly<PropsWithChildren & { variant: BoxVariant }>) {
  function getFieldsForVariant() {
    switch (variant) {
      case BoxVariant.Info:
        return {
          Icon: InfoCircleOutlined,
          bg: "bg-info-bg",
          text: "text-info-fg",
        };
      case BoxVariant.Warning:
        return {
          Icon: WarningOutlined,
          bg: "bg-warning-bg",
          text: "text-warning-fg",
        };
      case BoxVariant.Error:
        return {
          Icon: ExclamationCircleOutlined,
          bg: "bg-error-bg",
          text: "text-error-fg",
        };
    }
  }

  const { Icon, bg, text } = getFieldsForVariant();

  return (
    <div
      className={cn(
        `w-full p-4 rounded box-border flex justify-center flex-row gap-4 text-md`,
        bg,
        text,
      )}
    >
      <Icon className={text} />
      {children}
    </div>
  );
}

export function InfoBox({ children }: Readonly<PropsWithChildren>) {
  return <Box variant={BoxVariant.Info}>{children}</Box>;
}

export function TextInfoBox({ message }: Readonly<{ message: MessageKey }>) {
  const t = useTranslations();

  return (
    <InfoBox>
      <p>{t(message)}</p>
    </InfoBox>
  );
}
