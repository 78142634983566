import { useTranslations } from "@properate/translations";
import { NavLink, useNavigate } from "react-router-dom";
import {
  FormContextActionType,
  useFormContext,
  useFormValue,
} from "@/pages/alarms/details/FormContext";
import { useFormFieldIdsForCurrentPage } from "@/pages/alarms/details/hooks/useFormFieldIdsForPageId";
import useAlarmDetailsBaseUrl from "../hooks/useAlarmDetailsBaseUrl";
import {
  AlarmDetailsPageId,
  makeAlarmDetailsPageUrl,
  NEW_ALARM_VIEW_STATE_ENTRY,
  NewAlarmViewState,
  useIsCreateNew,
} from "../hooks/createNewAlarmHooks";

const viewByPageId: Record<
  AlarmDetailsPageId.Settings | AlarmDetailsPageId.Notifications,
  NewAlarmViewState
> = {
  [AlarmDetailsPageId.Settings]: NewAlarmViewState.Settings,
  [AlarmDetailsPageId.Notifications]: NewAlarmViewState.Notifications,
};

export function AlarmDetailsNav() {
  const baseUrl = useAlarmDetailsBaseUrl();

  const isCreateNew = useIsCreateNew();
  const [, setViewState] = useFormValue(NEW_ALARM_VIEW_STATE_ENTRY);
  const { dispatch } = useFormContext();
  const fieldIds = useFormFieldIdsForCurrentPage();
  const navigate = useNavigate();

  const validate = (
    pageId: AlarmDetailsPageId.Settings | AlarmDetailsPageId.Notifications,
  ) => {
    if (!isCreateNew) {
      return undefined;
    }

    return (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      dispatch({
        type: FormContextActionType.validateFields,
        ids: fieldIds,
        resultCallback: ({ hasError }) => {
          if (!hasError) {
            navigate(makeAlarmDetailsPageUrl(baseUrl, pageId));
            setViewState(viewByPageId[pageId]);
          }
        },
      });
    };
  };

  const t = useTranslations();
  const navLinkClassName = ({
    isActive,
    isDisabled,
  }: {
    isActive: boolean;
    isDisabled?: boolean;
  }) =>
    `text-xl font-semibold ${
      isActive
        ? "text-foreground underline underline-offset-4 decoration-primary decoration-2 "
        : "text-muted-foreground"
    }
    ${
      isDisabled
        ? "cursor-not-allowed opacity-30 hover:text-muted-foreground"
        : "cursor-pointer"
    }`;
  return (
    <nav className={"sticky top-0 bg-background-secondary z-20 pt-4"}>
      <ul className="list-none flex flex-row gap-5 justify-start border-solid border-0 border-b border-muted pb-4">
        <li>
          <NavLink
            onClick={validate(AlarmDetailsPageId.Settings)}
            to={makeAlarmDetailsPageUrl(baseUrl, AlarmDetailsPageId.Settings)}
            className={navLinkClassName}
            end
          >
            {t("alarm-details.nav.settings")}
          </NavLink>
        </li>
        <li className={"text-center"}>
          <NavLink
            onClick={validate(AlarmDetailsPageId.Notifications)}
            to={makeAlarmDetailsPageUrl(
              baseUrl,
              AlarmDetailsPageId.Notifications,
            )}
            className={navLinkClassName}
            end
          >
            {t("alarm-details.nav.notifications")}
          </NavLink>
        </li>
        <li>
          <NavLink
            aria-disabled={isCreateNew}
            to={makeAlarmDetailsPageUrl(baseUrl, AlarmDetailsPageId.Deviations)}
            className={({ isActive }) =>
              navLinkClassName({
                isActive,
                isDisabled: isCreateNew,
              })
            }
            onClick={(e) => {
              if (isCreateNew) {
                e.preventDefault();
              }
            }}
            end
          >
            {t("alarm-details.nav.deviations")}
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}
