import { cn } from "@properate/ui";
import { PropsWithChildren } from "react";
import { Pin } from "./types";
import { FloorPlanMapPinSetPointStrategy } from "./FloorPlanMapPinSetPointStrategy";
import { FloorPlanMapPinSensorStrategy } from "./FloorPlanMapPinSensorStrategy";
import { FloorPlanPinProvider } from "./FloorPlanMapPinContext";
import { FloorPlanMapPinValue } from "./FloorPlanMapPinValue";
import { FloorPlanMapPinPopover } from "./FloorPlanMapPinPopover";
import { useFloorPlanEditMode } from "./FloorPlanContext";
import { FloorPlanMapPinEditableSign } from "./FloorPlanMapPinEditableSign";
import { FloorPlanMapPinRoomStrategy } from "./FloorPlanMapPinRoomStrategy";
import { FloorPlanMapPinLabelStrategy } from "./FloorPlanMapPinLabelStrategy";

type Props = {
  pin: Pin;
};

export function FloorPlanMapPin(props: Props) {
  const [isEditing] = useFloorPlanEditMode();

  if (props.pin.type === "label") {
    return (
      <FloorPlanPinProvider pin={props.pin}>
        <FloorPlanMapPinPopover>
          <FloorPlanMapPinLabelStrategy />
        </FloorPlanMapPinPopover>
      </FloorPlanPinProvider>
    );
  }

  let FloorPlanMapPinOnClickStrategy: React.FC<PropsWithChildren>;

  if (props.pin.type === "room") {
    FloorPlanMapPinOnClickStrategy = FloorPlanMapPinRoomStrategy;
  } else {
    if (props.pin.type === "set-point-general") {
      FloorPlanMapPinOnClickStrategy = FloorPlanMapPinSetPointStrategy;
    } else {
      FloorPlanMapPinOnClickStrategy = FloorPlanMapPinSensorStrategy;
    }
  }

  return (
    <div
      className={cn(
        "flex gap-4 bg-background text-background-foreground rounded-full hover:bg-card",
        "border border-solid border-black",
        isEditing && "cursor-grab active:cursor-grabbing",
        props.pin.type === "set-point-general" &&
          "border-orange-400 shadow-orange-300/50 text-orange-600 dark:text-orange-300",
      )}
    >
      <FloorPlanPinProvider pin={props.pin}>
        <FloorPlanMapPinPopover>
          <FloorPlanMapPinOnClickStrategy>
            <FloorPlanMapPinEditableSign>
              <FloorPlanMapPinValue />
            </FloorPlanMapPinEditableSign>
          </FloorPlanMapPinOnClickStrategy>
        </FloorPlanMapPinPopover>
      </FloorPlanPinProvider>
    </div>
  );
}
