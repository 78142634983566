import * as React from "react";
import { Group } from "@visx/group";
import { OperationalPeriods } from "@/features/energy";
import { LegendDataForGraphLegend } from "../../types";
import type { ScaleTime } from "d3-scale";

type Props = {
  timeScale: ScaleTime<number, number>;
  graphHeight: number;
  graphWidth: number;
  legendData: LegendDataForGraphLegend;
};

const getStart = (p: OperationalPeriods, timeScale: Props["timeScale"]) =>
  timeScale(p.end) && timeScale(p.start) < 0 ? 0 : timeScale(p.start);

const getRangeWidth = (
  p: OperationalPeriods,
  timeScale: Props["timeScale"],
  graphWidth: Props["graphWidth"],
) => {
  const endTimeScale =
    timeScale(p.end) && timeScale(p.end) > graphWidth
      ? graphWidth
      : timeScale(p.end);
  const startTimeScale = getStart(p, timeScale);
  return endTimeScale - startTimeScale > 0 ? endTimeScale - startTimeScale : 0;
};
export const SimpleGraphAxesLegendData = ({
  legendData,
  timeScale,
  graphHeight,
  graphWidth,
}: Props) => {
  const {
    notePeriods,
    operationalScale,
    nonOperationalPeriods,
    holidayPeriods,
    enabledAlarmPeriods,
    visibility,
  } = legendData;
  return (
    <Group>
      {visibility.notes &&
        !!notePeriods &&
        notePeriods.map((p, i) => {
          return (
            <rect
              key={"notesDataPeriods_" + p.start + "_" + i}
              x={getStart(p, timeScale)}
              width={getRangeWidth(p, timeScale, graphWidth)}
              y={graphHeight + 5}
              height={9}
              fill={operationalScale("notes")}
            />
          );
        })}
      {visibility.nonOperational &&
        !!nonOperationalPeriods &&
        nonOperationalPeriods.map((p, i) => (
          <rect
            key={"nonOperational_" + p.start + "_" + i}
            x={getStart(p, timeScale)}
            width={getRangeWidth(p, timeScale, graphWidth)}
            y={graphHeight + 5}
            height={9}
            fill={operationalScale("nonOperational")}
          />
        ))}
      {visibility.holidays &&
        !!holidayPeriods &&
        holidayPeriods.map((p, i) => (
          <rect
            key={"holidays_" + p.start + "_" + i}
            x={getStart(p, timeScale)}
            width={getRangeWidth(p, timeScale, graphWidth)}
            y={graphHeight + 5}
            height={9}
            fill={operationalScale("holidays")}
          />
        ))}
      {visibility.alarmEnabled &&
        !!enabledAlarmPeriods &&
        enabledAlarmPeriods.map((p, i) => (
          <rect
            key={"enabledAlarms_" + p.start + "_" + i}
            x={getStart(p, timeScale)}
            width={getRangeWidth(p, timeScale, graphWidth)}
            y={graphHeight + 5}
            height={9}
            fill={operationalScale("alarmEnabled")}
          />
        ))}
    </Group>
  );
};
