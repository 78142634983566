import { DescriptionListAccordionBox } from "@properate/ui";
import { ReactNode } from "react";
import { useTranslations } from "@properate/translations";
import {
  formatTimeseriesName,
  formatUnit,
  getSystemCodeFromExternalId,
} from "@properate/common";
import { DoubleDatapoint, StringDatapoint } from "@cognite/sdk";
import dayjs from "@properate/dayjs";
import { useTimeseries } from "@/pages/alarms/details/hooks";
import { useFormValue } from "@/pages/alarms/details/FormContext";
import { IncidentFormFields } from "@/pages/alarmSystem/incident/utils";
import { formatNonScientific } from "@/utils/format";

export type TimeseriesAccordionBoxProps = {
  timeseriesId: number;
  title: ReactNode;
  htmlId?: string;
};

function formatValue(value: number | string, unit: string) {
  return `${
    typeof value == "number" ? formatNonScientific(value) : value
  }${formatUnit(unit)}`;
}

export function TimeseriesAccordionBox({
  timeseriesId,
  title,
  htmlId,
}: TimeseriesAccordionBoxProps) {
  const [incidentDatapoints] = useFormValue<
    Record<number, (DoubleDatapoint | StringDatapoint) & { unit: string }>
  >(IncidentFormFields.IncidentDatapoints);

  const { timeseries } = useTimeseries({ timeseriesId });
  const t = useTranslations(
    "alarm-details.deviations-page.summary.timeseries-accordion",
  );

  const alwaysVisibleEntries = [
    {
      term: t("name"),
      description: timeseries ? formatTimeseriesName(timeseries) : "",
    },
    {
      term: t("system"),
      description: timeseries?.externalId
        ? getSystemCodeFromExternalId(timeseries?.externalId)
        : "",
    },
    ...(incidentDatapoints?.[timeseriesId]
      ? [
          {
            term: t("closest-deviation-value"),
            description: t("deviation-at", {
              time: dayjs(incidentDatapoints[timeseriesId].timestamp).format(
                "DD/MM YYYY HH:mm",
              ),
              value: formatValue(
                incidentDatapoints[timeseriesId].value,
                incidentDatapoints[timeseriesId].unit,
              ),
            }),
          },
        ]
      : []),
    ...(timeseries?.metadata?.object_name
      ? [
          {
            term: t("object"),
            description: timeseries?.metadata?.object_name,
          },
        ]
      : []),
  ];

  const toggleableEntries = [
    {
      term: t("room-name"),
      description: timeseries?.metadata?.room_name,
    },
    {
      term: t("id"),
      description: timeseries?.externalId,
    },
    {
      term: t("client-id"),
      description: timeseries?.metadata?.client_id,
    },
    {
      term: t("source"),
      description: timeseries?.metadata?.source,
    },
  ];

  return (
    <DescriptionListAccordionBox
      htmlId={htmlId ?? `timeseries_${timeseriesId}`}
      ignoreEmptyDescriptions
      termColon
      title={title}
      alwaysVisibleEntries={alwaysVisibleEntries}
      toggleableEntries={toggleableEntries}
    />
  );
}
