import useSWR from "swr";
import { useEffect, useMemo } from "react";
import { App } from "antd";
import { useTranslations } from "@properate/translations";
import {
  AlarmRule,
  AlarmRuleTypeName,
  CompareTimeseriesAlarmRule,
  IncidentClientSide,
  type ThirdPartyAlarmRule,
} from "@properate/common";
import { Datapoints, DoubleDatapoint, StringDatapoint } from "@cognite/sdk";
import { getGroup, postNote, updateIncident } from "@/eepApi";
import { useHandleApiError } from "@/utils/api";
import { cogniteClient } from "@/services/cognite-client";
import { NoteLevel, NoteSource } from "@/features/notes";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useFormValue } from "@/pages/alarms/details/FormContext";
import { AlarmSettingsFormFields } from "@/pages/alarms/details/AlarmSettingsPage/types";
import { useAlarmTypeSpecificTimeseries } from "@/pages/alarms/details/components/AlarmDetailsNotesSidebar";
import { useGetAssetsFromTimeseriesIds } from "@/hooks/useGetAssetsFromTimeseriesIds";

export const useHasNextPerson = ({
  alertedGroupId,
  currentResponsibleUserEmail,
}: {
  alertedGroupId?: string;
  currentResponsibleUserEmail?: string;
}) => {
  const handleAPIError = useHandleApiError();

  const {
    data: responsibleMembers = [],
    isLoading: isLoadingMembers,
    error: errorLoadingMembers,
  } = useSWR(["alertedGroup", alertedGroupId], () => {
    return alertedGroupId
      ? getGroup(alertedGroupId).then((group) => group.members)
      : [];
  });

  const currentResponsiblePersonIndex = responsibleMembers.findIndex(
    (person) => {
      return person.properate_user_email === currentResponsibleUserEmail;
    },
  );

  if (errorLoadingMembers) {
    handleAPIError(errorLoadingMembers);
  }

  return useMemo(
    () => ({
      hasNextPerson:
        responsibleMembers.length > 1 &&
        currentResponsiblePersonIndex < responsibleMembers.length - 1,
      isLoading: isLoadingMembers,
    }),
    [currentResponsiblePersonIndex, responsibleMembers, isLoadingMembers],
  );
};

export enum IncidentFormFields {
  AlarmId = "AlarmId",
  DeviationId = "DeviationId",
  IncidentEventVisible = "IncidentEventVisible",
  IncidentDatapoints = "IncidentDatapoints",
}

export const getIncidentFormFields = ({
  alarmId,
  deviationId,
}: {
  alarmId?: string;
  deviationId?: number;
}) => ({
  [IncidentFormFields.AlarmId]: {
    defaultValue: alarmId || "",
  },
  [IncidentFormFields.DeviationId]: {
    defaultValue: deviationId || "",
  },
  [IncidentFormFields.IncidentEventVisible]: {
    defaultValue: true,
  },
  [IncidentFormFields.IncidentDatapoints]: {
    defaultValue: {},
  },
});

export type Comment = {
  id: number;
  createdTime: Date;
  createdBy: string;
  content: string;
};

export const useIncidentComments = (incident?: IncidentClientSide) => {
  const incidentId = incident?.id;
  const alarmEventIds = incident?.alarm_event_id_list || [];

  const currentBuilding = useCurrentBuilding();
  const { client } = useCogniteClient();
  const { notification } = App.useApp();
  const t = useTranslations();

  const [type] = useFormValue<AlarmRuleTypeName>(AlarmSettingsFormFields.Type);
  const timeseries = useAlarmTypeSpecificTimeseries(type);
  const { assets } = useGetAssetsFromTimeseriesIds({
    timeseriesIds: timeseries
      .filter((item) => !!item)
      .map((item) => item) as number[],
  });

  const {
    data,
    isLoading: isLoadingEvent,
    error: errorLoadingEvent,
  } = useSWR(["alarmEventById", alarmEventIds[0]], () => {
    return alarmEventIds[0]
      ? client.events.retrieve([{ id: alarmEventIds[0] }])
      : [];
  });

  useEffect(() => {
    if (errorLoadingEvent) {
      console.error("Error fetching alarm event", errorLoadingEvent);
      notification.error({
        message: t("incident.errors.error-fetching-incident"),
      });
    }
  }, [errorLoadingEvent, notification, t]);

  const {
    data: comments,
    mutate: mutateComments,
    isLoading: isLoadingComments,
    error: errorLoadingComments,
  } = useSWR(
    ["comments", incidentId],
    incidentId
      ? () => {
          return cogniteClient.events
            .list({
              filter: {
                metadata: { sourceId: incidentId },
              },
              sort: {
                createdTime: "desc",
              },
            })
            .then((events) => {
              return events.items?.map(
                (event) =>
                  ({
                    id: event.id,
                    createdTime: event.createdTime,
                    createdBy: event.metadata!.created_by,
                    content: event.metadata!.content,
                  }) as Comment,
              );
            });
        }
      : null,
    { fallbackData: [] },
  );

  useEffect(() => {
    if (errorLoadingComments) {
      console.error("Error fetching comments", errorLoadingComments);
      notification.error({
        message: t("incident.errors.error-fetching-comments"),
      });
    }
  }, [errorLoadingComments, notification, t]);

  const onSendComment = async (
    comment: string,
    callback?: VoidFunction,
    shouldUpdateIncidentLastComment?: boolean,
  ) => {
    const assetIds = data?.[0]?.assetIds;
    const timeNow = new Date().valueOf();

    if (assetIds) {
      try {
        const { data: note } = await postNote({
          note: {
            dataSetId: currentBuilding.dataSetId as number,
            content: comment,
            startTime: (data?.[0]?.startTime || timeNow) as number,
            endTime: (data?.[0]?.endTime || timeNow) as number,
            assetIds: assets?.map(({ id }) => id) ?? [],
            level: NoteLevel.INFO,
            source: NoteSource.WEB_INCIDENTS,
            sourceId: incidentId,
          },
          create: true,
        });
        shouldUpdateIncidentLastComment &&
          incidentId &&
          (await updateIncident(incidentId, {
            ...incident,
            last_comment: comment,
          }));
        await mutateComments(
          (data: Comment[] | undefined) => {
            return [
              {
                id: note.id,
                createdTime: note.createdTime,
                createdBy: note.metadata!.created_by,
                content: note.metadata!.content,
              },
              ...(data || []),
            ];
          },
          { revalidate: false },
        );
      } catch (error) {
        notification.error({
          message: t("incident.errors.error-adding-comment"),
        });
        console.error("Error adding comment", error);
      }
      callback && callback();
    } else {
      notification.error({
        message: t("incident.errors.error-adding-comment"),
      });
      console.error("No assetIds found");
    }
  };

  return {
    comments,
    isLoadingComments,
    onSendComment,
    isLoadingEvent,
  };
};

export const getTimespanBeforeAndAfterPeriod = (
  startTimespan: number,
  minutesToAdd: number,
) => {
  const after = startTimespan + minutesToAdd * 60 * 1000;
  const now = Date.now();
  const from = startTimespan - minutesToAdd * 60 * 1000;
  if (after < now) {
    return {
      from,
      to: after,
    };
  }
  return {
    from,
    to: now,
  };
};

export const useIncidentRelatedDatapoints = (
  startTimespan: number,
  alarmRule?: AlarmRule,
) => {
  const { client } = useCogniteClient();
  const handleAPIError = useHandleApiError();

  const [, setIncidentDatapoints] = useFormValue<
    Record<number, DoubleDatapoint | StringDatapoint>
  >(IncidentFormFields.IncidentDatapoints);

  const alarmTimeseries = useMemo(() => {
    if (!alarmRule) return [];
    const timeseries: (number | undefined)[] = [];

    if (alarmRule?.condition?.type_specific?.base_timeseries_id) {
      timeseries.push(alarmRule?.condition?.type_specific?.base_timeseries_id);
    }
    if (
      (alarmRule as CompareTimeseriesAlarmRule)?.condition?.type_specific
        ?.comparison_timeseries_id
    ) {
      timeseries.push(
        (alarmRule as CompareTimeseriesAlarmRule)?.condition?.type_specific
          ?.comparison_timeseries_id,
      );
    }
    if (
      (alarmRule as ThirdPartyAlarmRule)?.condition?.type_specific
        ?.visual_timeseries?.length
    ) {
      const visualTimeseries = (
        alarmRule as ThirdPartyAlarmRule
      )?.condition?.type_specific?.visual_timeseries.map((ts) => ts.value);
      timeseries.push(...visualTimeseries);
    }
    return [...new Set(timeseries.filter((ts) => !!ts))] as number[];
  }, [alarmRule]);

  const { data, error } = useSWR(
    alarmRule
      ? {
          type: "datapoints.retrieve",
          query: alarmTimeseries.map((ts) => ({
            id: ts,
            before: startTimespan + 5000,
          })),
        }
      : null,
    ({ query }) =>
      alarmRule
        ? (
            client.datapoints.retrieveLatest(query) as Promise<
              [Datapoints] | []
            >
          ).then((data) =>
            data.reduce((acc, curr) => {
              acc = {
                ...acc,
                [curr.id]: curr.datapoints.length
                  ? {
                      ...curr.datapoints?.[0],
                      unit: curr.unit,
                    }
                  : [],
              };
              return acc;
            }, {}),
          )
        : ({} as Record<
            number,
            (DoubleDatapoint | StringDatapoint) & { unit: string }
          >),
  );

  if (error) {
    handleAPIError(error);
  }

  useEffect(() => {
    if (data) {
      setIncidentDatapoints(data);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
};
