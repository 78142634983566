import { cn } from "@properate/ui";
import { Button, Form, Input, message, Popconfirm, Popover } from "antd";
import { useTranslations } from "@properate/translations";
import { useFloorPlanPin } from "./FloorPlanMapPinContext";
import { useFloorPlanUpdate } from "./hooks/useFloorPlanUpdate";
import { useFloorPlanPinPopover } from "./FloorPlanMapPinPopoverContext";

type FormValues = {
  label: string | undefined;
};

export function FloorPlanMapPinLabelStrategy() {
  const pin = useFloorPlanPin();
  const floorPlanUpdate = useFloorPlanUpdate();
  const [isPopoverOpen, handleOpenChange] = useFloorPlanPinPopover();
  const t = useTranslations();
  const [form] = Form.useForm<FormValues>();

  async function handleSubmit(values: FormValues) {
    if (values.label) {
      await floorPlanUpdate.trigger({
        pins: {
          remove: pin,
          insert: [
            {
              ...pin,
              type: "label",
              label: values.label,
            },
          ],
        },
      });

      handleOpenChange(false);
      form.resetFields();
    } else {
      message.warning(t("floor-plan-v2.errors.draft-is-empty"));
    }
  }

  async function handleDelete() {
    await floorPlanUpdate.trigger({
      pins: {
        remove: pin,
      },
    });
  }

  if (pin.type !== "label") {
    return null;
  }

  return (
    <Popover
      open={isPopoverOpen}
      onOpenChange={handleOpenChange}
      trigger="click"
      content={
        <div className="space-y-4">
          <Form<FormValues>
            form={form}
            initialValues={{ label: pin.label }}
            layout="vertical"
            onFinish={handleSubmit}
            disabled={floorPlanUpdate.isMutating}
          >
            <Form.Item<FormValues>
              label={t("floor-plan-v2.create-label-pin-label")}
              name="label"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Form>
          <div className="flex justify-end gap-2">
            <Popconfirm title={t("ui.confirm")} onConfirm={handleDelete}>
              <Button danger disabled={floorPlanUpdate.isMutating}>
                {t("ui.delete")}
              </Button>
            </Popconfirm>
            <Button
              type="primary"
              disabled={floorPlanUpdate.isMutating}
              onClick={() => form.submit()}
            >
              {t("ui.save")}
            </Button>
          </div>
        </div>
      }
    >
      <div
        className={cn(
          "px-4 py-1 bg-card text-card-foreground",
          "border border-solid border-black",
          "rounded-md",
          "cursor-pointer",
          "max-w-sm",
        )}
      >
        {pin.label}
      </div>
    </Popover>
  );
}
