import { useCallback, useContext, useMemo, useState } from "react";
import Color from "color";
import { scaleOrdinal } from "@visx/scale";
import { ThemeContext } from "styled-components";
import { Asset } from "@cognite/sdk";
import { OperationalHoursType, TimeSpan } from "@properate/common";
import { useNotes, useTimeseriesList } from "@/features/notes";
import {
  getHolidayPeriods,
  getNonOperationalPeriods,
  OperationalPeriods,
} from "@/features/energy";
import { useBuildingsSpec } from "@/services/buildingSpec";
import { LegendDataForGraphLegend } from "../../types";

export const useLegendDataForGraphLegend = (
  selectedBuildings: Asset[],
  zoomedTimeSpan: TimeSpan,
  isScatterplot: boolean,
): LegendDataForGraphLegend | undefined => {
  const setStartTime = zoomedTimeSpan[0];
  const setEndTime = zoomedTimeSpan[1];
  const themeContext = useContext(ThemeContext);
  const [visibility, setVisibility] = useState({
    notes: true,
    nonOperational: true,
    holidays: true,
    alarmEnabled: true,
  } as LegendDataForGraphLegend["visibility"]);
  const { timeseriesList } = useTimeseriesList();

  const onChangeVisibility = useCallback(
    (label: keyof LegendDataForGraphLegend["visibility"]) => {
      setVisibility((prev) => ({
        ...prev,
        [label]: !prev[label],
      }));
    },
    [],
  );

  const { notes } = useNotes();
  const buildings = useBuildingsSpec();

  const operationalHoursOfAllSelectedBuildings = useMemo(() => {
    return selectedBuildings.reduce((acc, building) => {
      const buildingSpec = buildings?.find(
        (b) => b.snapshotId === building.id.toString(),
      );
      if (buildingSpec) {
        acc = [...acc, ...buildingSpec.operationalHours];
      }
      return acc;
    }, [] as OperationalHoursType[]);
  }, [buildings, selectedBuildings]);

  const nonOperationalPeriods: OperationalPeriods[] = useMemo(() => {
    return getNonOperationalPeriods(
      operationalHoursOfAllSelectedBuildings,
      new Date(setStartTime),
      new Date(setEndTime),
    );
  }, [operationalHoursOfAllSelectedBuildings, setStartTime, setEndTime]);

  const holidayPeriods: OperationalPeriods[] = useMemo(
    () => getHolidayPeriods("d", new Date(setStartTime), new Date(setEndTime)),
    [setStartTime, setEndTime],
  );

  const noteRanges: LegendDataForGraphLegend["notePeriods"] = useMemo(() => {
    return (
      notes?.map(({ startTime, endTime, assetIds, content }) => {
        return {
          noteTitle: content,
          timeseriesIds:
            timeseriesList
              ?.filter((t) => t.assetId && assetIds.includes(t.assetId))
              .map((t) => t.id) || [],
          start: startTime < setStartTime ? setStartTime + 1 : startTime,
          end: endTime > setEndTime ? setEndTime : endTime,
        };
      }) || []
    );
  }, [notes, setEndTime, setStartTime, timeseriesList]);

  const NON_OPERATIONAL_COLOR = Color(themeContext.accent1)
    .alpha(0.8)
    .toString();
  const HOLIDAY_COLOR = themeContext.neutral4;
  const NOTE_COLOR = themeContext.accent12;
  const ALARM_ENABLED_COLOR = Color(themeContext.success).alpha(0.6).toString();

  const operationalScale = useMemo(
    () =>
      scaleOrdinal({
        domain: ["nonOperational", "holidays", "notes", "alarmEnabled"],
        range: [
          NON_OPERATIONAL_COLOR,
          HOLIDAY_COLOR,
          NOTE_COLOR,
          ALARM_ENABLED_COLOR,
        ],
      }) as LegendDataForGraphLegend["operationalScale"],
    [HOLIDAY_COLOR, NON_OPERATIONAL_COLOR, NOTE_COLOR, ALARM_ENABLED_COLOR],
  );

  return useMemo(
    () =>
      isScatterplot
        ? {
            notePeriods: noteRanges,
            visibility,
            onChangeVisibility,
            operationalScale,
          }
        : {
            notePeriods: noteRanges,
            operationalScale,
            nonOperationalPeriods,
            holidayPeriods,
            visibility,
            onChangeVisibility,
          },
    [
      isScatterplot,
      noteRanges,
      operationalScale,
      nonOperationalPeriods,
      holidayPeriods,
      visibility,
      onChangeVisibility,
    ],
  );
};
