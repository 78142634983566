import { Button, Form, InputNumber, Popover } from "antd";
import { PropsWithChildren } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import { useFloorPlanPin } from "./FloorPlanMapPinContext";
import { useFloorPlanPinTimeseries } from "./hooks/useFloorPlanPinTimeseries";
import { useFloorPlanPinStatus } from "./hooks/useFloorPlanPinStatus";
import { useFloorPlanPinStatusUpdate } from "./hooks/useFloorPlanPinStatusUpdate";
import {
  PinAlarms,
  ValueNameViewRoomDescription,
  ValueNameViewTimeseriesDescription,
} from "./FloorPlanMapPinValue";
import {
  FloorPlanPinPopoverProvider,
  useFloorPlanPinPopover,
} from "./FloorPlanMapPinPopoverContext";
import { RoomInfoCompatibilityAdapter } from "./FloorPlanMapPinSensorStrategy";
import { useFloorPlanPinSensor } from "./hooks/useFloorPlanPinSensor";

function FloorPlanMapPinPopoverImpl(props: PropsWithChildren) {
  const pin = useFloorPlanPin();
  const sensor = useFloorPlanPinSensor();
  const [isPopoverOpen, setPopoverOpen] = useFloorPlanPinPopover();

  if (pin.type === "label" || sensor?.view === "all") {
    return props.children;
  }

  return (
    <Popover
      open={isPopoverOpen}
      onOpenChange={setPopoverOpen}
      trigger={pin.type === "room" ? "click" : "hover"}
      title={
        <div className="w-64">
          {pin.type === "room" ? (
            <ValueNameViewRoomDescription roomId={pin.roomId} />
          ) : (
            <ValueNameViewTimeseriesDescription hideAlarms />
          )}
        </div>
      }
      content={
        pin.type === "room" ? (
          <RoomInfoCompatibilityAdapter
            roomId={pin.roomId}
            onClose={() => setPopoverOpen(false)}
          />
        ) : (
          <TimeseriesContent />
        )
      }
    >
      <div
        onClick={() => {
          // if we click on the pin, we close the popover because there
          // will be a modal showing the pin settings.
          if (pin.type !== "room") {
            setPopoverOpen(false);
          }
        }}
      >
        {props.children}
      </div>
    </Popover>
  );
}

function TimeseriesContent() {
  const t = useTranslations();
  const timeseries = useFloorPlanPinTimeseries();
  const status = useFloorPlanPinStatus();
  const statusUpdate = useFloorPlanPinStatusUpdate();
  const pin = useFloorPlanPin();

  function handleFinish(fields: { value: number }) {
    if (status.data) {
      statusUpdate.trigger({
        priority: status.data.priority,
        value: fields.value,
      });
    }
  }

  return (
    <div className="flex flex-col w-64">
      <PinAlarms />
      {pin.type === "set-point-general" && (
        <div className="flex items-center justify-between gap-4 pt-2">
          <div className="text-foreground/80">
            {t("floor-plan-v2.sensor-value")}:
          </div>
          <Form className="flex items-center gap-2" onFinish={handleFinish}>
            <Form.Item name="value" noStyle>
              <InputNumber
                key={status.data?.value}
                name="value"
                className="w-[93px]"
                defaultValue={status.data?.value}
                size="small"
                disabled={statusUpdate.isMutating}
                min={
                  typeof timeseries.data?.metadata?.min_value === "string"
                    ? parseInt(timeseries.data.metadata.min_value)
                    : undefined
                }
                max={
                  typeof timeseries.data?.metadata?.max_value === "string"
                    ? parseInt(timeseries.data.metadata.max_value)
                    : undefined
                }
              />
            </Form.Item>
            <Button
              htmlType="reset"
              type="text"
              icon={<CloseOutlined />}
              disabled={statusUpdate.isMutating}
              size="small"
            />
            <Button
              htmlType="submit"
              type="primary"
              icon={<CheckOutlined />}
              loading={statusUpdate.isMutating}
              size="small"
            >
              {t("floor-plan-v2.sensor-value-submit")}
            </Button>
          </Form>
        </div>
      )}
    </div>
  );
}

export function FloorPlanMapPinPopover(props: PropsWithChildren) {
  return (
    <FloorPlanPinPopoverProvider>
      <FloorPlanMapPinPopoverImpl>{props.children}</FloorPlanMapPinPopoverImpl>
    </FloorPlanPinPopoverProvider>
  );
}
