import Icon from "@ant-design/icons/lib/components/Icon";
import { FaRunning } from "@react-icons/all-files/fa/FaRunning";
import { WiCloud } from "@react-icons/all-files/wi/WiCloud";
import { WiHumidity } from "@react-icons/all-files/wi/WiHumidity";
import { WiThermometer } from "@react-icons/all-files/wi/WiThermometer";
import { Tooltip } from "antd";
import { useTranslations } from "@properate/translations";
import { ReactComponent as IndoorClimateSvg } from "@/layout/icons/meeting_room.svg";
import { ReactComponent as SetPointsSvg } from "../../floorPlanView/icons/tune.svg";
import { FloorPlanMapPinDragHandler } from "./FloorPlanMapDnD";

export function FloorPlanHeaderPinBar() {
  const t = useTranslations();

  return (
    <div className="flex gap-2">
      <Tooltip title={t("floor-plan-v2.sensor-drag-temperature")}>
        <FloorPlanMapPinDragHandler type="floor-sensor-temperature">
          <div className="flex items-center justify-center w-12 h-12 text-2xl text-black bg-white border border-dashed rounded cursor-move border-border">
            <WiThermometer />
          </div>
        </FloorPlanMapPinDragHandler>
      </Tooltip>
      <Tooltip title={t("floor-plan-v2.sensor-drag-gas")}>
        <FloorPlanMapPinDragHandler type="floor-sensor-gas">
          <div className="flex items-center justify-center w-12 h-12 text-2xl text-black bg-white border border-dashed rounded cursor-move border-border">
            <WiCloud />
          </div>
        </FloorPlanMapPinDragHandler>
      </Tooltip>
      <Tooltip title={t("floor-plan-v2.sensor-drag-humidity")}>
        <FloorPlanMapPinDragHandler type="floor-sensor-humidity">
          <div className="flex items-center justify-center w-12 h-12 text-2xl text-black bg-white border border-dashed rounded cursor-move border-border">
            <WiHumidity />
          </div>
        </FloorPlanMapPinDragHandler>
      </Tooltip>
      <Tooltip title={t("floor-plan-v2.sensor-drag-motion")}>
        <FloorPlanMapPinDragHandler type="floor-sensor-motion">
          <div className="flex items-center justify-center w-12 h-12 text-2xl text-black bg-white border border-dashed rounded cursor-move border-border">
            <FaRunning />
          </div>
        </FloorPlanMapPinDragHandler>
      </Tooltip>
      <Tooltip title={t("floor-plan-v2.sensor-drag-set-point")}>
        <FloorPlanMapPinDragHandler type="set-point-general">
          <div className="flex items-center justify-center w-12 h-12 text-2xl text-orange-600 bg-white border border-orange-400 border-dashed rounded cursor-move">
            <Icon component={SetPointsSvg} />
          </div>
        </FloorPlanMapPinDragHandler>
      </Tooltip>
      <Tooltip title={t("floor-plan-v2.sensor-drag-set-point")}>
        <FloorPlanMapPinDragHandler type="room">
          <div className="flex items-center justify-center w-12 h-12 text-2xl text-black bg-white border border-dashed rounded cursor-move border-border">
            <Icon component={IndoorClimateSvg} />
          </div>
        </FloorPlanMapPinDragHandler>
      </Tooltip>
      <Tooltip title={t("floor-plan-v2.sensor-drag-label")}>
        <FloorPlanMapPinDragHandler type="label">
          <div className="flex items-center justify-center w-12 h-12 text-2xl text-black bg-white border border-dashed rounded cursor-move border-border">
            <BookmarkIcon />
          </div>
        </FloorPlanMapPinDragHandler>
      </Tooltip>
    </div>
  );
}

function BookmarkIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
      />
    </svg>
  );
}
