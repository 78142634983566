import { SensorList } from "@properate/common";
import { Fragment, useRef, useState } from "react";
import { useTranslations } from "@properate/translations";
import { Button, InputNumber, message, Popconfirm, Spin, Tooltip } from "antd";
import Icon, {
  CloseOutlined,
  HistoryOutlined,
  InfoCircleOutlined,
  LinkOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { useUser } from "@properate/auth";
import { IoHandRight } from "@react-icons/all-files/io5/IoHandRight";
import { IoHandRightOutline } from "@react-icons/all-files/io5/IoHandRightOutline";
import { useTheme } from "styled-components";
import { cn, CustomDndProvider } from "@properate/ui";
import useSWRMutation from "swr/mutation";
import { collection, doc, setDoc } from "firebase/firestore";
import { browserFirestore } from "@properate/firebase";
import { updateSetPoint } from "@/eepApi";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { TimeseriesSelectionModal } from "@/features/timeseries";
import { AuditLogModal } from "@/components/AuditLog";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { ReactComponent as DragHandleIcon } from "@/components/Draggable/icons/drag-handle.svg";
import { DEFAULT_MESSAGE_DURATION } from "@/utils/helpers";
import { formatMeasurementForSchema } from "../SchemaView/TechnicalSchema/utils";
import { parseError } from "../utils";
import { useRoomInfo } from "./useRoomInfo";
import { RoomImportedTimeseries, RoomSensors, RoomSensorValue } from "./types";

type Props = {
  roomId: number;
  sensors: Record<string, SensorList>;

  requestOpenGraphModal: (timeseriesId: number) => void;
  requestDeleteRoom?: () => void;

  onOk: () => void;
};

export function RoomInfoContent(props: Props) {
  const user = useUser();
  const t = useTranslations();
  const building = useCurrentBuilding();
  const [showAllTimeseries, setShowAllTimeseries] = useState(false);
  const [showWritableTimeseries, setShowWritableTimeseries] = useState(false);

  const {
    sensors,
    room,
    isLoading,
    showHistoryForImportedTimeseries,
    setShowHistoryForImportedTimeseries,
    importedTimeseriesList,
    setImportedTimeseriesList,
    handleChangeTimeseries,
  } = useRoomInfo({
    id: props.roomId,
    sensors: props.sensors,
  });

  const deleteRoom = useSWRMutation(
    ["room", props.roomId, "delete"],
    () => props.requestDeleteRoom?.(),
  );

  const selectedIds = [
    ...new Set(importedTimeseriesList.map((ts) => ts.timeseriesId)),
  ];
  const showSelectTimeseries = showAllTimeseries || showWritableTimeseries;

  async function handleOk() {
    for (const imported of importedTimeseriesList) {
      if (imported.touched) {
        try {
          await updateSetPoint({
            priority: imported.priority,
            value: imported.value,
            external_id: imported.externalId,
            audit_source: "web",
          });
        } catch (error) {
          message.error(
            t("room-info.cant-update-setpoint", {
              error: parseError(error),
            }),
          );
        }
      }
    }

    props.onOk();
  }

  async function handleCopyRoomLink() {
    const ROOM_INFO_URL =
      process.env.REACT_APP_KEYCLOAK_REALM! === "prod"
        ? "https://app.properate.com/room"
        : "https://dev.eepcloud.no/room";
    const url = `${ROOM_INFO_URL}/${props.roomId}`;
    await navigator.clipboard.writeText(url);
    message.success(t("room-info.link-copied"));
  }

  return (
    <section>
      <header className="flex justify-between">
        <div>
          {room
            ? `${room.subBuilding} ${t("room-info.floor", {
                floor: room.floor,
              })}`
            : ""}
        </div>
        {user.isAdmin && (
          <Button type="link" className="px-3" onClick={handleCopyRoomLink}>
            <LinkOutlined /> {t("room-info.copy-link")}
          </Button>
        )}
      </header>
      {isLoading ? (
        <div className="flex justify-center items-center h-[69px]">
          <Spin />
        </div>
      ) : (
        <main className="my-6 max-h-80 overflow-y-scroll -mr-3 pr-3 space-y-6">
          <IndoorClimate
            room={sensors}
            hasImportedTimeseries={importedTimeseriesList.length > 0}
            onClickValue={(roomInfo) =>
              props.requestOpenGraphModal(roomInfo.id)
            }
          />
          <ImportedTimeseriesList
            roomId={props.roomId}
            timeseriesList={importedTimeseriesList}
            setTimeseriesList={setImportedTimeseriesList}
            requestTimeseriesHistoryModal={setShowHistoryForImportedTimeseries}
            requestOpenGraphModal={props.requestOpenGraphModal}
          />
        </main>
      )}
      <footer className="flex gap-2">
        <Button
          disabled={user.isViewer}
          onClick={() => setShowAllTimeseries(true)}
        >
          {t("room-info.import-measures")}
        </Button>
        <Button
          disabled={user.isViewer}
          onClick={() => setShowWritableTimeseries(true)}
        >
          {t("room-info.import-setpoint")}
        </Button>
        {props.requestDeleteRoom && (
          <Popconfirm
            title={t("ui.confirm")}
            onConfirm={() => deleteRoom.trigger()}
          >
            <Button disabled={user.isViewer} loading={deleteRoom.isMutating}>
              {t("ui.delete")}
            </Button>
          </Popconfirm>
        )}
        <Button type="primary" onClick={handleOk}>
          {t("room-info.ok")}
        </Button>
      </footer>
      <TimeseriesSelectionModal
        open={Boolean(room && showSelectTimeseries)}
        hiddenFilters={["building"]}
        initialFilters={{
          category: showAllTimeseries ? "default" : "setPoint",
          buildingId: building.rootId,
          subBuilding: room?.subBuilding ? room.subBuilding : undefined,
        }}
        onHide={() => {
          setShowAllTimeseries(false);
          setShowWritableTimeseries(false);
        }}
        selectedIds={selectedIds}
        max={200}
        onOk={handleChangeTimeseries}
        initialSearch={showAllTimeseries ? room?.name : ""}
      />
      {showHistoryForImportedTimeseries && (
        <AuditLogModal
          name={`${showHistoryForImportedTimeseries.name} ${showHistoryForImportedTimeseries.description}`}
          externalId={showHistoryForImportedTimeseries.externalId}
          onHide={() => setShowHistoryForImportedTimeseries(undefined)}
        />
      )}
    </section>
  );
}

function IndoorClimate(props: {
  room: RoomSensors | undefined;
  hasImportedTimeseries: boolean;
  onClickValue: (value: RoomSensorValue) => void;
}) {
  const t = useTranslations();

  if (props.room === undefined) {
    return (
      <div className="text-muted-foreground text-center col-span-2 w-full">
        {t("floor-plan-v2.errors.unable-to-load-room-info")}
      </div>
    );
  }

  type RoomSensorType =
    | "temperature"
    | "moisture"
    | "CO2"
    | "VOC"
    | "radon"
    | "motion";

  const values: [RoomSensorType, RoomSensorValue][] = [];
  const EMPTY_VALUE = "--";

  if (
    props.room.temperature.mean !== undefined &&
    props.room.temperature.mean.value !== EMPTY_VALUE
  ) {
    values.push(["temperature", props.room.temperature.mean]);
  }

  if (
    props.room.humidity_sensor.mean !== undefined &&
    props.room.humidity_sensor.mean.value !== EMPTY_VALUE
  ) {
    values.push(["moisture", props.room.humidity_sensor.mean]);
  }

  if (
    props.room.co2.mean !== undefined &&
    props.room.co2.mean.value !== EMPTY_VALUE
  ) {
    values.push(["CO2", props.room.co2.mean]);
  }

  if (
    props.room.VOC.mean !== undefined &&
    props.room.VOC.mean.value !== EMPTY_VALUE
  ) {
    values.push(["VOC", props.room.VOC.mean]);
  }

  if (
    props.room.radon.mean !== undefined &&
    props.room.radon.mean.value !== EMPTY_VALUE
  ) {
    values.push(["radon", props.room.radon.mean]);
  }

  if (
    props.room.motion !== undefined &&
    props.room.motion.value !== EMPTY_VALUE
  ) {
    values.push(["motion", props.room.motion]);
  }

  if (values.length === 0) {
    return (
      <div className="text-muted-foreground text-center col-span-2 w-full">
        {t("floor-plan-v2.errors.no-sensors-in-room")}
      </div>
    );
  }

  return (
    <div className="grid gap-y-2 gap-x-8 grid-cols-[1fr_auto]">
      {values.map(([label, value]) => (
        <Fragment key={label}>
          <div>{t(`room-info.${label}`)}</div>
          <Button
            type="link"
            size="small"
            className="flex gap-2 items-center justify-end px-0"
            onClick={() => props.onClickValue(value)}
          >
            {value.alarm && (
              <Tooltip title={value.alarm}>
                {value.alarmType === "error" ? (
                  <div className="text-red-600">
                    <WarningOutlined />
                  </div>
                ) : value.alarmType === "warning" ? (
                  <div className="text-yellow-600">
                    <WarningOutlined />
                  </div>
                ) : (
                  <div className="text-blue-600">
                    <InfoCircleOutlined />
                  </div>
                )}
              </Tooltip>
            )}
            {value.value}
          </Button>
        </Fragment>
      ))}
      {props.hasImportedTimeseries && (
        <hr className="col-span-2 w-full border-0 bg-border h-px m-0 mt-4" />
      )}
    </div>
  );
}

function ImportedTimeseriesList(props: {
  roomId: number;
  timeseriesList: RoomImportedTimeseries[];
  setTimeseriesList: (timeseriesList: RoomImportedTimeseries[]) => void;
  requestTimeseriesHistoryModal: (timeseries: RoomImportedTimeseries) => void;
  requestOpenGraphModal: (timeseriesId: number) => void;
}) {
  if (props.timeseriesList.length === 0) {
    return null;
  }

  return (
    <CustomDndProvider>
      <div className="flex flex-col gap-2">
        {props.timeseriesList.map((ts, index) => (
          <TimeseriesListItem
            key={ts.relationshipExternalId}
            roomId={props.roomId}
            ts={ts}
            index={index}
            timeseriesList={props.timeseriesList}
            setTimeseriesList={props.setTimeseriesList}
            requestTimeseriesHistoryModal={props.requestTimeseriesHistoryModal}
            requestOpenGraphModal={props.requestOpenGraphModal}
          />
        ))}
      </div>
    </CustomDndProvider>
  );
}

type DragItem = {
  id: string;
  index: number;
};

function TimeseriesListItem(props: {
  index: number;
  roomId: number;
  ts: RoomImportedTimeseries;
  timeseriesList: RoomImportedTimeseries[];
  setTimeseriesList: (timeseriesList: RoomImportedTimeseries[]) => void;
  requestTimeseriesHistoryModal: (timeseries: RoomImportedTimeseries) => void;
  requestOpenGraphModal: (timeseriesId: number) => void;
}) {
  const t = useTranslations();
  const user = useUser();
  const theme = useTheme();
  const { client } = useCogniteClient();
  const containerRef = useRef<HTMLDivElement>(null);
  const dragRef = useRef<HTMLDivElement>(null);

  const updateOrder = useSWRMutation(
    ["rooms", props.roomId, props.index],
    async (
      [, roomId, currentIndex],
      { arg: { index: newIndex } }: { arg: DragItem },
    ) => {
      const newImportedTimeseriesOrder = [...props.timeseriesList];
      const [movedItem] = newImportedTimeseriesOrder.splice(currentIndex, 1);
      newImportedTimeseriesOrder.splice(newIndex, 0, movedItem);

      await setDoc(
        doc(collection(browserFirestore, "rooms"), roomId.toString()),
        {
          importedTimeseriesOrder: newImportedTimeseriesOrder.map(
            (ts) => ts.timeseriesId,
          ),
        },
      );
    },
    {
      onSuccess() {
        message.success(t("room-info.timeseries-order-changed"));
      },
      onError() {
        message.error(t("room-info.failed-to-save-timeseries-order"));
      },
    },
  );

  const [{ handlerId }, drop] = CustomDndProvider.useDrop<
    DragItem,
    void,
    { handlerId: string | symbol | null }
  >({
    accept: "timeseries-item",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover: CustomDndProvider.buildHoverHandler({
      currentIndex: props.index,
      containerRef,
      onMove: (dragIndex, hoverIndex) => {
        const dragItem = props.timeseriesList[dragIndex];
        const hoverItem = props.timeseriesList[hoverIndex];

        const newTimeseriesList = props.timeseriesList.map((item) =>
          item.relationshipExternalId === dragItem.relationshipExternalId
            ? hoverItem
            : item.relationshipExternalId === hoverItem.relationshipExternalId
            ? dragItem
            : item,
        );

        props.setTimeseriesList(newTimeseriesList);
      },
    }),
  });

  const [{ isDragging }, drag] = CustomDndProvider.useDrag({
    type: "timeseries-item",
    item: () =>
      ({ id: props.ts.relationshipExternalId, index: props.index }) as DragItem,
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    end(item) {
      updateOrder.trigger({
        id: item.id,
        index: item.index,
      });
    },
  });

  drop(containerRef);
  drag(dragRef);

  function handleChangeValue(ts: RoomImportedTimeseries, value: number | null) {
    props.setTimeseriesList(
      props.timeseriesList.map((item) =>
        item.relationshipExternalId === ts.relationshipExternalId
          ? {
              ...item,
              value: value === null ? undefined : value,
              touched: item.value !== value,
            }
          : item,
      ),
    );
  }

  async function handleChangePriority(ts: RoomImportedTimeseries) {
    props.setTimeseriesList(
      props.timeseriesList.map((item) =>
        item.relationshipExternalId === ts.relationshipExternalId
          ? {
              ...item,
              overrideValue: undefined,
            }
          : item,
      ),
    );
    try {
      await updateSetPoint({
        // todo: is this supposed to be a hard-coded priority?
        priority: 8,
        value: undefined,
        external_id: ts.relationshipExternalId,
        audit_source: "web",
      });
    } catch (error) {
      const errorMessage = parseError(error);
      message.open({
        type: "error",
        content: t("writable.edit-set-point-modal.error-update-set-point", {
          errorMessage,
        }),
        duration: DEFAULT_MESSAGE_DURATION,
      });
    }
  }

  async function handleDelete(ts: RoomImportedTimeseries) {
    await client.relationships.delete([
      { externalId: ts.relationshipExternalId },
    ]);

    props.setTimeseriesList(
      props.timeseriesList.filter(
        (item) => item.relationshipExternalId !== ts.relationshipExternalId,
      ),
    );
  }

  return (
    <div
      ref={containerRef}
      className={cn("flex items-center", {
        "opacity-50": isDragging,
      })}
    >
      <Tooltip placement="top" title={t("dashboard.widgets.drag-and-drop")}>
        <Button
          type="text"
          ref={dragRef}
          data-handler-id={handlerId}
          icon={<Icon component={DragHandleIcon} />}
        />
      </Tooltip>
      <div className="flex-1 pl-2 pr-8">
        <div>{props.ts.description || "--"}</div>
        <div className="text-xs text-muted-foreground">{props.ts.name}</div>
      </div>
      <div className="flex gap-1 items-center justify-between">
        {props.ts.type === "writable" ? (
          <Fragment>
            <InputNumber
              disabled={user.isViewer}
              value={props.ts.value}
              decimalSeparator=","
              suffix={props.ts.unit !== "" ? props.ts.unit : undefined}
              onChange={(value) => handleChangeValue(props.ts, value)}
            />
            <Button
              disabled={user.isViewer}
              onClick={() => handleChangePriority(props.ts)}
              {...(props.ts.priority === 8
                ? {
                    style: { color: theme.primary },
                    icon: <IoHandRight className="mt-1" />,
                  }
                : {
                    icon: <IoHandRightOutline className="mt-1" />,
                  })}
            />
            <Button
              icon={<HistoryOutlined className="mt-1" />}
              onClick={() => props.requestTimeseriesHistoryModal(props.ts)}
            />
          </Fragment>
        ) : (
          <Button
            type="link"
            className="flex-1 text-right"
            onClick={() => props.requestOpenGraphModal(props.ts.timeseriesId)}
          >
            {formatMeasurementForSchema({
              value: props.ts.value,
              unit: props.ts.unit,
              stateDescription: props.ts.stateDescription,
            })}
          </Button>
        )}
        <Popconfirm
          disabled={user.isViewer}
          title={t("ui.confirm")}
          onConfirm={() => handleDelete(props.ts)}
          okText={t("ui.delete")}
          cancelText={t("ui.cancel")}
        >
          <Button
            icon={<CloseOutlined className="mt-1" />}
            disabled={user.isViewer}
          />
        </Popconfirm>
      </div>
    </div>
  );
}
