import { Form, Input, message, Modal } from "antd";
import { useTranslations } from "@properate/translations";

type Props = {
  open: boolean;
  onHide: () => void;
  onOk: (label: string) => void;
};

type FormValues = {
  label: string | undefined;
};

export function FloorPlanMapPinLabelModal(props: Props) {
  const t = useTranslations();
  const [form] = Form.useForm<FormValues>();

  function handleSubmit(values: FormValues) {
    if (values.label) {
      props.onOk(values.label);
      props.onHide();
      form.resetFields();
    } else {
      message.warning(t("floor-plan-v2.errors.draft-is-empty"));
    }
  }

  return (
    <Modal
      open={props.open}
      onCancel={props.onHide}
      onOk={() => form.submit()}
      title={t("floor-plan-v2.create-label-pin-title")}
      centered
      width={500}
    >
      <Form<FormValues> form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item<FormValues>
          label={t("floor-plan-v2.create-label-pin-label")}
          name="label"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
