import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useFloorPlanEditMode } from "./FloorPlanContext";
import { useFloorPlanMapMoveHandlers } from "./FloorPlanMapContext";

type Context = [boolean, Dispatch<SetStateAction<boolean>>];

const PopoverContext = createContext<Context>([
  false,
  () => console.warn("Popover context not initialized"),
]);

export function FloorPlanPinPopoverProvider(props: PropsWithChildren) {
  const state = useState(false);

  return (
    <PopoverContext.Provider value={state}>
      {props.children}
    </PopoverContext.Provider>
  );
}

export function useFloorPlanPinPopover() {
  const [isEditing] = useFloorPlanEditMode();
  const [isPopoverOpen, setPopoverOpen] = useContext(PopoverContext);
  const moveHandlers = useFloorPlanMapMoveHandlers();

  useEffect(() => {
    const handler = () => setPopoverOpen(false);

    moveHandlers.add(handler);

    return () => {
      moveHandlers.delete(handler);
    };
  }, [moveHandlers, setPopoverOpen]);

  function handleOpenChange(open: boolean) {
    if (isEditing) {
      return;
    }

    setPopoverOpen(open);
  }

  return [isPopoverOpen, handleOpenChange] as const;
}
