import { useTranslations } from "@properate/translations";
import {
  MeterSelectionForm,
  MeterSelectionFormUiOptions,
} from "@/components/MeterSelectionModal/MeterSelectionForm";
import { BaseMeter } from "./types";
import { WhiteModal } from "./elements";

interface MeterSelectionModalUiOptions extends MeterSelectionFormUiOptions {
  maskClosable?: boolean;
  width?: number;
}

interface Props {
  open: boolean;
  meter: BaseMeter | null;
  onOk: (meter: BaseMeter) => void;
  onHide: () => void;
  isLoading?: boolean;
  descriptionHidden?: boolean;
  uiOptions?: MeterSelectionModalUiOptions;
}

export function MeterSelectionModal({
  open,
  meter,
  onOk,
  onHide,
  isLoading = false,
  descriptionHidden = false,
  uiOptions = {},
}: Props) {
  const t = useTranslations();
  function getTitle() {
    if (meter) {
      if (meter.meterType === "water") {
        return t("timeseries.meter-selection-modal.title-select-water-meter");
      }
      return t("timeseries.meter-selection-modal.title-select-energy-meter");
    }
    return null;
  }

  function handleFinish(
    meterValues: Pick<
      BaseMeter,
      "isAccumulated" | "source" | "subBuilding" | "system" | "unit"
    >,
  ) {
    if (meter) {
      onOk({
        ...meter,
        ...meterValues,
      });
    }
  }

  const finalUiOptions = {
    ...{
      maskClosable: false,
      width: 0,
    },
    ...uiOptions,
  };

  return (
    <WhiteModal
      title={<h3>{getTitle()}</h3>}
      centered
      open={open}
      onCancel={onHide}
      footer={null}
      destroyOnClose
      width={finalUiOptions.width || 600}
      maskClosable={finalUiOptions.maskClosable}
    >
      {meter && (
        <MeterSelectionForm
          meter={meter}
          onFinish={handleFinish}
          descriptionHidden={descriptionHidden}
          isLoading={isLoading}
          onCancel={onHide}
          uiOptions={finalUiOptions}
        />
      )}
    </WhiteModal>
  );
}
